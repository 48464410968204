import { Box } from "@chakra-ui/layout";
import Header, { scrollToBottomHeader } from "../shared/Header/Header";
import Footer from "../shared/Footer";
import { VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { routes } from "../router";

const Landing = () => {
  return (
    <Box className="page">
      <Header />
      <Box className="gradient-block">
        <Box className="container">
          <VStack>
            <h1>Over ons</h1>

            <div className="spacer-s" />

            <Box p="0 30px">
              <p>
                We zijn een klein en gedreven team dat zich inzet voor educatie
                en training rondom reptielen. Met passie en expertise delen we
                onze kennis over deze fascinerende dieren met een breed publiek.
                Voor basisscholen, middelbare scholen en hogescholen verzorgen
                we leerzame gastlessen, voor noodhulpdiensten zoals de politie,
                brandweer en medisch personeel bieden we waardevolle trainingen
                aan, voor verzorgers van gifslangen en giftige hagedissen in
                gevangenschap hebben we introductietrainingen en we dienen als
                consultant voor dierentuinen, dierspeciaalzaken en andere
                instellingen met reptielen.
              </p>
            </Box>

            <div className="spacer-s" />

            <div className="opaque">
              <h2>Onze missie</h2>

              <div className="spacer-s" />

              <p>
                Insularis is opgezet om een mooiere wereld achter te laten voor
                de generaties na ons. We focussen op het verbeteren van de
                reputatie van reptielen, en met name slangen, om deze
                fascinerende en vaak misbegrepen dieren beter te beschermen.
                Slangen komen voor op alle continenten behalve Antarctica en
                spelen een cruciale rol in ecosystemen wereldwijd. Helaas worden
                ze door veel mensen gevreesd en vervolgd vanwege mythes en
                misvattingen. Door voorlichting te geven over de schoonheid,
                complexiteit en het nut van slangen voor de natuur, hopen we
                meer begrip en waardering voor deze dieren te kweken. Als
                slangen en andere reptielen beter beschermd worden, profiteren
                automatisch ook de habitats waarin ze leven. Deze habitats
                herbergen talloze andere diersoorten, zeldzame planten en vormen
                complexe, kwetsbare ecosystemen. Door ons in te zetten voor deze
                fascinerende reptielen, kunnen we dus indirect bijdragen aan het
                behoud van biodiversiteit wereldwijd. Met onze gastlessen hopen
                we dan ook de angst rond deze dieren te doorbreken, zodat
                toekomstige generaties kunnen opgroeien in een wereld waarin
                reptielen gewaardeerd worden en hun belangrijke rol in de natuur
                intact is. Zo kunnen we samen de aarde een stukje mooier maken
                en doorgeven aan onze kinderen.
              </p>
            </div>
          </VStack>
        </Box>
      </Box>

      <Box className="black-block">
        <Box className="container">
          <h1>Onze diensten</h1>
        </Box>
      </Box>

      <Box className="gradient-block">
        <Box className="container">
          <VStack>
            <h2>Gastlessen</h2>

            <div className="spacer-s"></div>

            <p>
              Reptielen gastlessen zijn een unieke en leerzame ervaring voor
              elke leeftijdsgroep. Onze deskundige gastdocenten brengen diverse
              reptielen mee naar de klas of andere locatie. Kinderen en
              volwassenen zijn vaak gefascineerd door deze exotische dieren.
              Tijdens de interactieve les leren de deelnemers over de
              verschillende soorten reptielen, hun leefomgeving, voedingspatroon
              en gedrag. De docent laat de deelnemers de dieren van dichtbij
              bekijken en voorzichtig aanraken als ze dat willen. Voor
              basisschool kinderen is de les aangepast met eenvoudige uitleg en
              meer tijd om de dieren te observeren. Lessen op middelbare
              scholen, hoger onderwijs en volwassenen krijgen meer
              gedetailleerde informatie over de anatomie, evolutie en rol van
              reptielen in het ecosysteem. Onze ervaren gastdocenten
              beantwoorden ook alle vragen van de deelnemers. De reptielen
              gastles is niet alleen leuk en spannend, maar geeft ook meer
              waardering voor deze wonderlijke dieren en hun belang in de
              natuur. Wij stemmen de lesinhoud graag af op het niveau en de
              interesses van de doelgroep.
            </p>

            <div className="spacer-s"></div>

            <Link to={routes.gastlessen} onClick={scrollToBottomHeader}>
              Meer over onze gastlessen
            </Link>
          </VStack>
        </Box>
      </Box>
      {/* 
      <Box className="black-block">
        <Box className="container">
          <VStack>
            <h2>Lezingen</h2>
            <p>
              Reptielen zijn fascinerende dieren die al miljoenen jaren op aarde
              leven. Onze organisatie biedt boeiende lezingen aan voor iedereen
              die geïnteresseerd is in deze koudbloedige wezens. Of u nu wilt
              leren over hun unieke voortplanting, giftige
              verdedigingsmechanismen of evolutionaire aanpassingen, wij hebben
              een lezing die perfect bij u past.
            </p>
            <p>
              Onze ervaren herpetologen kunnen diepgaande lezingen verzorgen
              over de reproductie van reptielen. U leert alles over hun vreemde
              paringsrituelen, eiafzetting en broedzorg. Sommige soorten leggen
              eieren, terwijl anderen levendbarend zijn. De lezing behandelt ook
              de verschillen tussen geslachtsbepaling bij reptielen in
              vergelijking met zoogdieren.
            </p>
            <p>
              Voor de liefhebbers van giftige dieren bieden we lezingen aan over
              de intrigerende wereld van reptielentoxines. U leert alles over de
              evolutie van slangengif, de werking van verschillende toxines en
              hun effect op prooidieren en mensen. Ook krijgt u inzicht in de
              medicinale toepassingen van reptielengiften.
            </p>
            <p>
              Ongeacht uw specifieke interesse, wij hebben de perfecte lezing
              met boeiende feiten en prachtige foto's. Laat u onderdompelen in
              de fascinerende wereld van reptielen!
            </p>
          </VStack>
        </Box>
      </Box> */}

      <Box className="black-block">
        <Box className="container">
          <VStack>
            <h2>Trainingen</h2>

            <div className="spacer-s"></div>

            <p>
              Onze uitgebreide gifslangentrainingen zijn gericht op
              verschillende doelgroepen. Voor noodhulpdientsten zoals politie en
              brandweer hebben we speciale trainingen ontwikkeld om hen voor te
              bereiden op situaties met gifslangen. Tijdens deze intensieve
              trainingen leren de deelnemers hoe ze de situatie goed kunnen
              inschatten, welke maatregelen ze moeten nemen en hoe ze het beste
              kunnen handelen. Voor ambulancepersoneel en medewerkers van de
              spoedeisende hulp zijn er trainingen waarin zij leren hoe ze
              slachtoffers van een gifslangenbeet op de juiste manier behandelen
              en vervoeren. Daarnaast bieden we beginnende verzorgers van
              gifslangen de kans om via onze training de nodige vaardigheden op
              te doen. Tijdens interactieve sessies leren ze stap voor stap hoe
              ze de dieren veilig kunnen verplaatsen en hanteren. Verder leren
              ze over huisvesting, protocollen, beschermingsmiddelen en meer.
            </p>

            <div className="spacer-s"></div>

            <Link to={routes.trainingen} onClick={scrollToBottomHeader}>
              Meer over onze trainingen
            </Link>
          </VStack>
        </Box>
      </Box>

      {/* <Box className="gradient-block">
        <Box className="container">
          <VStack>
            <h2>Consultancy</h2>

            <div className="spacer-s"></div>

            <p>
              Wanneer het gaat om het huisvesten van reptielen is het cruciaal
              om de juiste expertise in te schakelen om de beste leefomgeving te
              creëren. Onze consultants zijn gespecialiseerd in het adviseren
              over reptielenverblijven en helpen u graag met het ontwerpen van
              de ideale habitat. Tijdens een uitgebreid consult luisteren we
              aandachtig naar uw wensen en behoeften. Op basis van de specifieke
              diersoort formuleren we gepersonaliseerde aanbevelingen. We letten
              daarbij op alle details: van de juiste temperatuur,
              luchtvochtigheid en verlichting tot de inrichting en grootte van
              het verblijf. Ons doel is een veilige en stimulerende omgeving die
              tegemoetkomt aan de natuurlijke leefwijze van uw huisdier. We
              selecteren en adviseren over de beste materialen en voorzieningen,
              zoals verwarmingselementen, schuilplaatsen en klimmogelijkheden.
              Zo creëren we samen de ideale thuisbasis voor uw reptiel. Onze
              expertise garandeert dat het verblijf voldoet aan de hoogste
              veiligheidsnormen. Bovendien denken we graag mee over handige
              extra's om de verzorging te vergemakkelijken. Wij hebben de kennis
              om uw dier gelukkig en gezond te houden. Laat ons u helpen met het
              ontwerpen van de perfecte displays.
            </p>

            <div className="spacer-s"></div>

            <Link to={routes.consultancy} onClick={scrollToBottomHeader}>
              Meer over onze consultancy
            </Link>
          </VStack>
        </Box>
      </Box> */}

      <Footer />
    </Box>
  );
};

export default Landing;
