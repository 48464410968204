import { Box } from "@chakra-ui/react";
import { useRouteError } from "react-router-dom";
import Header from "./shared/Header/Header";
import Footer from "./shared/Footer";

export default function ErrorPage() {
  const error = useRouteError() as { statusText: String; message: String };
  console.error(error);

  return (
    <Box className="page" w="100vw" h="100vh">
      <Header fixedHeight={85} />
      <div
        style={{ width: "70vw", position: "fixed", left: "15vw", top: "45vh" }}
      >
        <h2>
          Heyy, something went wrong :/ You can use the navigation on the top to
          visit existing pages
        </h2>
      </div>
      <div style={{ position: "fixed", bottom: 0 }}>
        <Footer />
      </div>
    </Box>
  );
}
