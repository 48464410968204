import { Box } from "@chakra-ui/layout";
import Header from "../shared/Header/Header";
import Footer from "../shared/Footer";
import EmailInfo from "../contact/EmailInfo";
import PhoneMobile from "../contact/PhoneMobile";

const ContactPage = () => {
  return (
    <Box className="page">
      <Header />
      {/* Contact Form: Include a user-friendly contact form for inquiries or support. */}
      {/* <Box className="gradient-block">
        <Box className="container">
          <ContactForm />
        </Box>
      </Box> */}
      {/* Contact Information: Provide multiple contact methods (email, phone, address). */}
      <Box className="gradient-block">
        <Box className="container">
          <div
            style={{
              height: "fit-content",
              alignContent: "start",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                flex: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <h2 style={{ textAlign: "start" }}>Contact</h2>
              <div className="spacer-s" />
              <p>
                Email: <EmailInfo className="onhover-color-white" />
              </p>
              {/* <p>
                Podcast: <EmailPodcast className="onhover-color-white" />
              </p> */}
              <p>
                Telefoon: <PhoneMobile />
              </p>
            </div>
            <Box flex={1} />
            <div
              style={{
                flex: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <h2>Locaties</h2>
              <div className="spacer-s" />
              <p style={{ textAlign: "start" }}>
                We hebben momenteel nog geen bezoekersadres
              </p>
            </div>
          </div>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default ContactPage;
