import "./Header.css";
import { Box } from "@chakra-ui/layout";
import logo from "../../insularis_logo.svg";
import text from "../../insularis_text.svg";
import { useEffect, useState } from "react";
import Fab from "../Fab/Fab";
import { Link, To } from "react-router-dom";
import { NavigationRoute, navigationRoutes } from "../../router";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { Colors } from "../../theme";

const Header = ({ fixedHeight }: { fixedHeight?: number }) => {
  const [headerHeight, setHeaderHeight] = useState(
    fixedHeight || window.innerHeight * 0.85 - window.scrollY
  );

  useEffect(() => {
    const handleScroll = () =>
      setHeaderHeight(
        fixedHeight || window.innerHeight * 0.85 - window.scrollY || 0
      );

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [fixedHeight]);

  const showFab = window.innerWidth < 1024;
  const showRoutes = window.innerWidth >= 1024;
  const moveRoutes = window.innerWidth < 1400;

  const maxHeight = fixedHeight || window.innerHeight * 0.85;

  return (
    <section>
      <header
        className="App-header"
        style={{ height: Math.max(headerHeight, 85) + "px" }}
      >
        <Images
          height={Math.max(headerHeight, 85)}
          factor={Math.max(
            (headerHeight - 85) / (window.innerHeight * 0.85 - 85),
            0
          )}
        />
        {showRoutes && (
          <Routes
            moveRoutes={moveRoutes}
            factor={Math.max(
              (headerHeight - 85) / (window.innerHeight * 0.85 - 85),
              0
            )}
          />
        )}
      </header>
      <div style={{ height: maxHeight + "px" }} />
      {showFab && <Fab />}
    </section>
  );
};

export default Header;

export const scrollToBottomHeader = () => {
  window.scrollTo({ top: window.innerHeight * 0.85 - 85, behavior: "auto" });
};

const exponential = (min: number, max: number, factor: number) => {
  return min * Math.pow(max / min, factor);
};

const linear = (min: number, max: number, factor: number) => {
  return min + factor * (max - min);
};

const Images = ({ height, factor }: { height: number; factor: number }) => {
  const iconStartWidth = 85 * 0.6;
  const iconEndWidth = window.innerHeight * 0.85 * 0.6;

  const iconWidth = exponential(iconStartWidth, iconEndWidth, factor);

  const iconStartLeftPadding = 85 * 0.2;
  const iconEndLeftPadding = (window.innerWidth - iconEndWidth) / 2;

  const iconPaddingLeft = exponential(
    iconStartLeftPadding,
    iconEndLeftPadding,
    factor
  );

  const iconPaddingTop = linear(85 * 0.2, 0.07 * window.innerHeight, factor);

  const textStartWidth = (85 * 0.6 * 0.8 * 1751) / 200;
  const textEndWidth = Math.min(
    window.innerWidth * 0.8,
    (height * 0.15 * 1751) / 200
  );

  const textWidth = linear(textStartWidth, textEndWidth, factor);

  const textPaddingTop =
    height -
    (textWidth / 1751) * 200 -
    (height * 0.2) / 0.8 +
    linear(0, 0.15 * height, factor);

  const textStartLeftPadding = 85;
  const textEndLeftPadding = (window.innerWidth - textEndWidth) / 2;

  const textPaddingLeft = linear(
    textStartLeftPadding,
    textEndLeftPadding,
    factor
  );

  return (
    <Box>
      <img
        src={logo}
        className="no-pointer"
        alt="logo"
        width={iconWidth}
        style={{
          position: "fixed",
          top: iconPaddingTop,
          left: iconPaddingLeft,
        }}
      />
      <img
        src={text}
        className="no-pointer"
        alt="logo"
        width={textWidth}
        style={{
          position: "fixed",
          top: textPaddingTop,
          left: textPaddingLeft,
        }}
      />
    </Box>
  );
};

const Routes = ({
  factor,
  moveRoutes,
}: {
  factor: number;
  moveRoutes: boolean;
}) => {
  return (
    <Box
      className="routes"
      style={{
        top: moveRoutes ? linear(0, -200, factor) : 0,
      }}
    >
      {navigationRoutes.map(({ name, path }, i) => {
        if (path instanceof Array)
          return <NavigationDropdown key={i} route={{ name, path }} />;

        return (
          <Route key={i} to={path}>
            {name.toString()}
          </Route>
        );
      })}
    </Box>
  );
};

interface RouteProps {
  to: To;
  children: string;
}

const Route = ({ to, children }: RouteProps) => (
  <Link to={to as To} className="route" onClick={scrollToBottomHeader}>
    {children}
  </Link>
);

export const NavigationDropdown = ({
  route: { name, path },
}: {
  route: NavigationRoute;
}) => {
  return (
    <Menu>
      <MenuButton as={Link} className="route">
        {name}
      </MenuButton>
      <MenuList style={{ backgroundColor: "transparent", border: "none" }}>
        {(path as { name: String; path: To }[]).map((nestedItem) => (
          <MenuItem style={{ backgroundColor: Colors.black }}>
            <Route to={nestedItem.path}>{nestedItem.name.toString()}</Route>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
