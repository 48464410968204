import EmailInfo from "../contact/EmailInfo";
import InstagramIconButton from "../socials/InstagramIconButton";
import { Box, HStack, VStack } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box className="black-block">
      <Box className="container">
        <HStack>
          <Box flex={1}>
            <VStack>
              <h2>Contact</h2>
              <EmailInfo />
              <HStack>
                <InstagramIconButton />
                {/* <XIconButton />
                <LinkedInIconButton /> */}
              </HStack>
            </VStack>
          </Box>

          <Box flex={1}>
            <VStack>
              {/* <h2>Over ons</h2> */}
              {/* Company Overview: Provide an in-depth overview of your software company, including its history, mission, and values. */}
              {/* <Link to="/story">Ons verhaal</Link> */}

              {/* Team: Introduce key team members and their roles to establish trust. */}
              {/* <Link to="/team">Ons team</Link> */}

              {/* Culture: Highlight your company culture, values, and commitment to innovation. */}
              {/* <Link to="/culture">TODO</Link> */}
            </VStack>
          </Box>
        </HStack>
        <p>© 2024 Insularis</p>
      </Box>
    </Box>
  );
};

export default Footer;
