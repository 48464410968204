import { Box } from "@chakra-ui/react";
import Footer from "../shared/Footer";
import Header from "../shared/Header/Header";
import PriceLine from "../shared/Text/PriceLine";
import InfoLine from "../shared/Text/InfoLine";

const TrainingPage = () => {
  return (
    <div className="page">
      <Header />
      <Box className="gradient-block">
        <Box className="container">
          <h1>Trainingen</h1>
          {/* <div className="spacer-s" />
          <a href="#brandweer-politie">
            <h3>Politie en Brandweer</h3>
          </a>
          <a href="#medisch-personeel">
            <h3>Medisch personeel</h3>
          </a>
          <a href="#verzorgers">
            <h3>Beginnende verzorgers gifslangen</h3>
          </a> */}
        </Box>
      </Box>

      <Box className="black-block" id="brandweer-politie">
        <Box className="container">
          <h2>Politie en Brandweer</h2>

          <div className="spacer-s" />

          <p>
            We bieden uitgebreide trainingen aan voor zowel politie als
            brandweer om ervoor te zorgen dat deze hulpdiensten goed voorbereid
            zijn op het omgaan met gevaarlijke dieren. Allereerst is het
            belangrijk dat het personeel geen angst heeft voor dieren in het
            algemeen. Daarom beginnen we met een interactieve icebreaker sessie
            waarin ze op een ontspannen manier kunnen kennismaken met enkele
            niet-gevaarlijke dieren. Dit zorgt ervoor dat ze met een open en
            nieuwsgierige blik de daadwerkelijke training ingaan. Vervolgens
            geven we een uitgebreide presentatie over de kenmerken van zowel
            gevaarlijke als ongevaarlijke diersoorten. Aan de hand van
            duidelijke afbeeldingen en videobeelden leren ze de signalen
            herkennen om in te schatten of een dier gevaarlijk is. Daarna volgt
            het praktijkgedeelte waarin ze oefenen met het veilig benaderen,
            vangen en vervoeren van echte gevaarlijke dieren, uiteraard onder
            streng toezicht van onze experts. Dit is essentieel zodat ze de
            vaardigheden daadwerkelijk eigen maken en vertrouwen krijgen in hun
            aanpak. Met deze grondige trainingen zorgen we ervoor dat politie en
            brandweer klaar zijn voor elke risicovolle situatie met gevaarlijke
            dieren. Na deze training bieden we een assortiment aan beschermende
            kleding, vangmaterialen en vervoerskooien aan, zodat de hulpdiensten
            optimaal uitgerust aan de slag kunnen met de nieuw verworven kennis.
          </p>

          {/* <div className="spacer-s"/>

          <PriceLine price={500} unit="dagdeel" />
          <p>
            De prijs is afgestemd op groepen van 12 personen, voor meerdere
            groepen is het mogelijk om een aangepast schema te maken of om met
            meer personeel langs te komen.
          </p> */}

          <div className="spacer-s" />

          <InfoLine />
        </Box>
      </Box>
      {/* Onze trainingen voor noodhulpdiensten voorzien in cruciale
              inzichten en praktische oefeningen voor het veilig hanteren en het
              ongevaarlijk stellen van gevaarlijke reptielen. We beginnen met
              theorie over de anatomie en gedrag van verschillende soorten
              gevaarlijke reptielen, zodat deelnemers de risico's kunnen
              inschatten in de praktijk. Vervolgens oefenen we stap voor stap de
              juiste technieken om giftige slangen en hagedissen veilig te
              vangen, vast te houden en veilig te stellen. Deelnemers leren hoe
              ze beschermende kleding en hulpmiddelen moeten gebruiken en
              oefenen met zowel ongevaarlijke dieren als giftige dieren. Zo zijn
              ze voorbereid als ze in het echt geconfronteerd worden met
              gevaarlijke reptielen. */}
      <Box className="gradient-block" id="medisch-personeel">
        <Box className="container">
          <h2>Medisch personeel</h2>

          <div className="spacer-s" />

          <p>
            De trainingen die we aanbieden voor medisch personeel van de spoed
            eisende hulp en ambulances zijn cruciaal om hen te helpen bij de
            behandeling van slangenbeten. Tijdens deze intensieve trainingen
            ligt de focus op het leren herkennen van de verschillende soorten
            slangen en hun kenmerkende beten. We besteden uitgebreid aandacht
            aan de identificatie van inheemse adders maar ook aan exotische
            slangen die gehouden worden als huisdier. De cursisten leren waar ze
            snel en efficiënt de juiste informatie kunnen vinden over de
            specifieke symptomen en de meest geschikte behandeling van elk type
            slangenbeet. We maken daarbij gebruik van de nieuwste richtlijnen en
            inzichten om te zorgen dat medisch personeel altijd de beste zorg
            kan bieden. Een belangrijk onderdeel is ook het oefenen van
            realistische simulaties, zodat de cursisten in de praktijk vertrouwd
            raken met alle stappen die nodig zijn bij de aanpak van een
            slachtoffer met een slangenbeet. Deze grondige training geeft
            medisch personeel de kennis en vaardigheden die ze nodig hebben om
            adequaat en snel te kunnen handelen bij slangenbeten, wat van
            levensbelang kan zijn voor de patiënt.
          </p>

          <div className="spacer-s" />

          <InfoLine />
        </Box>
      </Box>
      <Box className="black-block" id="verzorgers">
        <Box className="container">
          <h2>Beginnende verzorgers gifslangen</h2>

          <div className="spacer-s" />

          <p>
            Het veilig en verantwoord leren hanteren van giftige reptielen zoals
            gifslangen en giftige hagedissen is een belangrijke vaardigheid voor
            verzorgers van deze dieren. Daarom bieden wij gespecialiseerde
            trainingen aan voor zowel individuen als kleine groepen die willen
            leren hoe ze deze reptielen op de juiste manier kunnen hanteren en
            verzorgen.
          </p>

          <div className="spacer-s" />

          <p>
            Onze trainingen zijn op maat gemaakt en bevatten zowel theorie als
            praktische oefeningen. Tijdens de theorielessen behandelen we
            uitgebreid de anatomie en gedrag van gifslangen en giftige
            hagedissen, en bespreken we de risico's en voorzorgsmaatregelen die
            nodig zijn. Ook gaan we in op de verschillende hanteringsstrategieën
            en -technieken die het veiligst zijn voor zowel de dieren als de
            verzorgers. Deze trainingen worden aangepast aan de wensen van de
            groep, we zullen hierbij dan ook de families en geslachten waar
            interesse in is uitgebreider bespreken en dieren proberen te regelen
            die hier ook op aansluiten.
          </p>

          <div className="spacer-s" />

          <p>
            In de praktijklessen kunnen deelnemers onder begeleiding zelf
            oefenen met het oppakken, vasthouden en verplaatsen van onze
            gifslangen en giftige hagedissen. Veiligheid staat hierbij altijd
            voorop. Zo leren we de deelnemers hoe ze de dieren met zorg en
            respect kunnen hanteren, terwijl risico's tot een minimum beperkt
            worden.
          </p>

          <div className="spacer-s" />

          <PriceLine price={125} unit="persoon per dagdeel" />
          <p>
            Voor individuen zullen we een wachtlijst hanteren totdat we
            voldoende deelnemers hebben. Aanmelden voor deze trainingen kan tot
            groepen van 4 personen.
          </p>

          <div className="spacer-s" />

          <InfoLine />
        </Box>
      </Box>

      <Footer />
    </div>
  );
};

export default TrainingPage;
