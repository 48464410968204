interface PriceLineProps {
  price: number;
  unit: string;
  isWithTax?: boolean;
}

const PriceLine = ({ price, isWithTax = false, unit }: PriceLineProps) => (
  <p>
    <b>
      Prijs: €{price},- {isWithTax ? "incl." : "ex."} btw per {unit}
    </b>
  </p>
);

export default PriceLine;
