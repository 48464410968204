import { To, createBrowserRouter } from "react-router-dom";
import Landing from "./routes/Landing";
import ErrorPage from "./ErrorPage";
import ContactPage from "./routes/Contact";
import SchoolLecturesPage from "./routes/SchoolLectures";
import TrainingPage from "./routes/Training";
// import ConsultancyPage from "./routes/Consultancy";

export const routes = {
  home: "/",
  gastlessen: "/gastlessen",
  trainingen: "/trainingen",
  // consultancy: "/consultancy",
  contact: "/contact",
};

const router = createBrowserRouter([
  {
    path: routes.home,
    element: <Landing />,
    errorElement: <ErrorPage />,
  },
  {
    path: routes.gastlessen,
    element: <SchoolLecturesPage />,
  },
  // {
  //   path: "/lezingen",
  //   element: <LecturesPage />,
  // },
  {
    path: routes.trainingen,
    element: <TrainingPage />,
  },
  // {
  //   path: routes.consultancy,
  //   element: <ConsultancyPage />,
  // },
  {
    path: routes.contact,
    element: <ContactPage />,
  },
  // {
  //   path: "/team",
  //   element: <TeamPage />,
  // },
  // {
  //   path: "/story",
  //   element: <StoryPage />,
  // },
]);

export default router;

export interface NavigationRoute {
  name: String;
  path: To | NavigationRoute[];
  nested?: true;
}

export const navigationRoutes: NavigationRoute[] = [
  { name: "Home", path: routes.home },
  {
    name: "Diensten",
    nested: true,
    path: [
      {
        name: "Gastlessen",
        path: routes.gastlessen,
      },
      // {
      //   name: "Lezingen",
      //   path: "/lezingen",
      // },
      {
        name: "Trainingen",
        path: routes.trainingen,
      },
      // {
      //   name: "Consultancy",
      //   path: routes.consultancy,
      // },
    ],
  },
  { name: "Contact", path: routes.contact },
];

export const flatMapNavigationRoutes = (
  routes: NavigationRoute[]
): NavigationRoute[] => {
  const newRoutes: NavigationRoute[] = [];

  for (const route of routes) {
    if (route.nested) {
      for (const nestedRoute of flatMapNavigationRoutes(
        route.path as NavigationRoute[]
      )) {
        newRoutes.push(nestedRoute);
      }
    } else {
      newRoutes.push(route);
    }
  }

  return newRoutes;
};
