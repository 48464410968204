import { Box } from "@chakra-ui/react";
import Footer from "../shared/Footer";
import Header from "../shared/Header/Header";
import PriceLine from "../shared/Text/PriceLine";
import InfoLine from "../shared/Text/InfoLine";

const SchoolLecturesPage = () => {
  return (
    <div className="page">
      <Header />
      <Box className="gradient-block">
        <Box className="container">
          <h1>Gastlessen</h1>
        </Box>
      </Box>

      <Box className="black-block">
        <Box className="container">
          <h2>Basisscholen</h2>

          <div className="spacer-s" />

          <p>
            De gastlessen voor basischolen over slangen bieden de jonge
            leerlingen een unieke kans om kennis te maken met deze fascinerende
            dieren. Voor veel kinderen zijn slangen nog omgeven door mysterie en
            roepen ze angst op. Tijdens de gastles wordt die angst weggenomen
            door de slangen van dichtbij te laten zien. Onze gastdocent laat de
            prachtige schubben en unieke lichaamsbouw zien terwijl hij of zij
            uitlegt dat slangen helemaal niet eng zijn. De kinderen mogen de
            slang zelfs even aanraken! Ze ervaren dat de gladde huid helemaal
            niet glibberig of vies aanvoelt. Ook leren ze dat de meeste slangen
            helemaal niet gevaarlijk zijn voor mensen.
          </p>

          <div className="spacer-s" />

          <p>
            Vervolgens legt de gastdocent op een leuke manier uit hoe slangen
            leven. De kinderen horen wat slangen eten, hoe ze jagen en hoe ze
            zich voortbewegen. De gastdocent laat zien hoe lenig slangen zijn en
            hoe ze zich in bochten kunnen wikkelen. Ook de vorktong waarmee
            slangen ruiken zullen ze zelf te werk zien gaan. Zo leren de
            kinderen op een interactieve manier alles over het leven van deze
            dieren. Aan het einde van de les hebben de leerlingen geen angst
            meer voor slangen. In plaats daarvan hebben ze bewondering gekregen
            voor deze buitengewone dieren. Afhankelijk van de leeftijd en het
            energie niveau van de klas zijn er meerdere mogelijkheden voor de
            opzet van de les.
          </p>

          <PriceAndBooking price={75} />
        </Box>
      </Box>
      <Box className="gradient-block">
        <Box className="container">
          <h2>Middelbare scholen</h2>

          <div className="spacer-s" />

          <p>
            Onze gastlessen op middelbare scholen bieden een unieke ervaring
            voor de leerlingen door een combinatie van een presentatie die
            aansluit op de lesstof, gevolgd door het daadwerkelijk aanraken en
            vasthouden van slangen. In de presentatie behandelen wij
            interessante onderwerpen die aansluiten bij de reguliere lesstof van
            biologie, zoals de ordening van reptielen, de verschillende manieren
            van voortbewegen bij reptielen en de rol van reptielen in
            voedselketens als predator maar ook als prooi, afhankelijk van de
            lesstof die op dat moment behandeld wordt. Door deze onderwerpen te
            koppelen aan de ervaring van het zien en aanraken van de dieren,
            worden de lesstof en theorie veel tastbaarder en begrijpelijker voor
            de leerlingen. Wanneer de presentatie voorbij is, krijgen de
            leerlingen onder toezicht de kans om zelf een aantal slangensoorten
            aan te raken en eventueel zelfs even vast te houden. Dit is altijd
            een spannend en bijzonder moment, waarbij de leerlingen hun angst en
            terughoudendheid voor deze dieren kunnen overwinnen. Met de juiste
            begeleiding en uitleg ervaren de leerlingen hoe de gladde, soms ook
            ruwe huid van de slangen aanvoelt, hoe de spieren zich samentrekken
            tijdens het bewegen en hoe de tong regelmatig tevoorschijn komt. Het
            is een unieke, onvergetelijke ervaring die het klassikaal leren over
            dieren in het echt tot leven brengt.
          </p>

          <PriceAndBooking price={75} />
        </Box>
      </Box>
      {/* <Box className="black-block">
        <Box className="container">
          <h2>Hogescholen</h2>

          <div className="spacer-s" />

          <p>
            Onze organisatie biedt unieke leermogelijkheden voor studenten van
            verschillende opleidingen door middel van gastlessen en het uitlenen
            van onze dieren. Tijdens deze interactieve colleges gaan we dieper
            in op specifieke onderwerpen met betrekking tot de zorg voor en het
            gedrag van onze dieren. Denk aan colleges over de anatomie, de
            voortplanting of het gedrag. Onze medewerkers delen graag hun
            jarenlange expertise en ervaring met de verzorging van exotische
            diersoorten met de studenten. Zo krijgen zij een unieke kijk in de
            dagelijkse praktijk van het werken met deze bijzondere dieren.
          </p>

          <div className="spacer-s" />

          <p></p><p>
            Voor boekingen stuur een email naar{" "}
            <EmailBooking className="bold" />.
          </p>
        </Box>
      </Box> */}

      <Footer />
    </div>
  );
};

export default SchoolLecturesPage;

const PriceAndBooking = ({ price }: { price: number }) => (
  <div>
    <div className="spacer-s" />

    <PriceLine price={price} unit="lesuur" />

    <div className="spacer-s" />

    <InfoLine />
  </div>
);
