import { extendTheme } from "@chakra-ui/theme-utils";

const theme = extendTheme({
  colors: {
    brand: {
      100: "#4cb8c4",
      900: "#3cd3ad",
    },
  },
});

export default theme;

export class Colors {
  static get blue(): string {
    return "#4cb8c4";
  }
  static get white(): string {
    return "#fafafa";
  }
  static get black(): string {
    return "#212121";
  }
  static get green(): string {
    return "#3cd3ad";
  }
}
