import { useState } from "react";
import "./Fab.css";
import logo from "../../insularis_logo.svg";
import { Link, To } from "react-router-dom";
import { flatMapNavigationRoutes, navigationRoutes } from "../../router";
import { scrollToBottomHeader } from "../Header/Header";

const Fab = () => {
  const [rotation, setRotation] = useState(0);

  const flatNavigationRoutes = flatMapNavigationRoutes(navigationRoutes);

  const toggleHamburgerMenu = () => {
    if (rotation === 0 || rotation === 180) return openHamburgerMenu();
    return closeHamburgerMenu();
  };
  const openHamburgerMenu = () => {
    setRotation(90);
  };
  const closeHamburgerMenu = () => {
    setRotation(0);
  };

  return (
    <section>
      <button className="fab" onClick={toggleHamburgerMenu}>
        <img
          src={logo}
          className="fab-logo"
          alt="logo"
          style={{
            transform: `rotate(${rotation}deg)`,
            transition: "transform 0.3s ease",
          }}
        />
      </button>
      <div className={rotation === 0 ? "translated" : ""}>
        {flatNavigationRoutes.map((val, i) => (
          <DropdownItem
            key={i}
            index={i}
            route={val as { name: String; path: To }}
          />
        ))}
      </div>
    </section>
  );
};

export default Fab;

const DropdownItem = ({
  index,
  route: { name, path },
}: {
  index: number;
  route: { name: String; path: To };
}) => {
  return (
    <Link
      className="dropdown-item"
      to={path}
      style={{
        top: 135 + 50 * index + "px",
      }}
      onClick={scrollToBottomHeader}
    >
      {name}
    </Link>
  );
};
